/**
 * 加载js
 */
export const loadjs = (() => {
  const loadMap = {};
  return (...paths) => {
    const load = path =>
      new Promise((resolve, reject) => {
        if (loadMap[path]) {
          resolve();
        } else {
          var script = document.createElement('script');
          script.setAttribute('type', 'text/javascript');
          script.onload = () => {
            loadMap[path] = true;
            resolve();
          };
          script.onerror = reject;
          script.setAttribute('src', path);
          document.body.appendChild(script);
        }
      });
    return Promise.all(paths.map(it => load(it)));
  };
})();
