<template>
  <span class="upload-input">
    <textarea
      style="height: 400px"
      :id="myTextareaId"
      placeholder="请输入"
    ></textarea>
  </span>
</template>

<script>
import { loadjs } from '../lib/load';
import { instance } from '../api/base/index';

export default {
  name: 'tinyEditor',
  props: {
    value: String,
    height: Number,
    configStr: String,
  },
  data () {
    if (window.editorIndex) {
      window.editorIndex++;
    } else {
      window.editorIndex = 1;
    }
    return {
      myTextareaId: `editor_${window.editorIndex}`,
      editor: null,
      lastValue: '',
    };
  },
  created () {
    this.init();
  },
  watch: {
    value (newV, oldV) {
      console.log(oldV);
      if (this.lastValue !== newV) {
        try {
          this.editor.setContent(newV);
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  methods: {
    // plugins  defined_text 添加自定义结点
    async init () {
      await loadjs(
        process.env.VUE_APP_PREFIX + '/javascript/tinymce/tinymce.min.js',
      );
      setTimeout(async () => {
        window.tinymce.init({
          plugins:
            'fullscreen image lists advlist imagetools preview table hr pagebreak searchreplace wordcount insertdatetime link emoticons code importword ' +
            this.configStr,
          selector: `#${this.myTextareaId}`,
          language: 'zh_CN',
          branding: false,
          resize: false,
          content_style: `
          img,table {
            max-width:100%;
          } 
          tr:first-child{
            background:#f7f7f7
          } 
          tr:last-child{
            background:#fff
          }
          `,
          block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
          toolbar:
            ' fontselect  |  formatselect  |  fontsizeselect  | bold italic underline strikethrough  forecolor backcolor | alignleft aligncenter alignright indent indent2em lineheight | bullist numlist  blockquote |preview fullscreen defined_text importword',
          menubar: 'edit insert view format table tools help',
          font_formats:
            '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif',
          color_cols: 5,
          custom_colors: true,
          color_map: [
            'FECAC9',
            'light Rose',
            'FF9A8F',
            'blush pink',
            'FF646D',
            'coral pink',
            'FF3464',
            'red pink',
            'FF0000',
            'cherry red',

            'FFECD6',
            'pale',
            'FED7A9',
            'light peach',
            'FCC37F',
            'peach',
            'FFA54F',
            'dull orange',
            'FC8E35',
            'dusty orange',

            '#FDFFCF',
            'lvory',
            '#FAFF95',
            'Buff',
            '#F9FF75',
            'Butter Yellow',
            '#F4FF42',
            'Banana Yellow',
            '#ffff33',
            'off Yellow',

            '99ffcc',
            'lvory',
            '99ff99',
            'Buff',
            '66ff66',
            'Butter Yellow',
            '33ff33',
            'off Yellow',
            '00cc00',
            'off Yellow',

            'ccffff',
            'lvory',
            '99ffff',
            'lvory',
            '66ffff',
            'Buff',
            '33ffff',
            'Butter Yellow',
            '00ccff',
            'Banana Yellow',

            'CCCCCC',
            'Dark azure',
            'AAAAAA',
            'Navy Blue',
            '999999',
            'Maroon',
            '666666',
            'Very dark gray',
            '333333',
            'Indigo',
          ],
          // 图片
          automatic_uploads: true,
          file_picker_types: 'image',
          init_instance_callback: (editor) => {
            this.editor = editor;
            this.editor.setContent(this.value);
            editor.on('change', () => {
              try {
                this.$parent.clearValidate();
                // eslint-disable-next-line no-empty
              } catch (error) { }
              const lastValue = this.editor.getContent();
              this.lastValue = lastValue;
              this.$emit('input', lastValue);
            });
          },
          images_upload_handler: function (blobInfo, succFun, failFun) {
            const forms = new FormData();
            const file = blobInfo.blob();
            forms.append('file', file);
            instance
              .post('/content.file.upload.json', forms, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: window.localStorage.getItem('token'),
                },
              })
              .then((res) => {
                succFun(res.data.data);
              })
              .catch((e) => [failFun('HTTP Error: ' + e.status)]);
          },
        });
      });
    },
  },
  mounted () { },
};
</script>
<style lang="scss">
.tox.tox-tinymce.tox-fullscreen {
  margin-left: 210px !important;
  margin-top: 60px !important;
  width: calc(100% - 210px) !important;
}
.tox-tinymce-aux {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  z-index: 130000 !important;
}
</style>
<style lang="scss" scoped>
.is-error .upload-input {
  border-color: #f56c6c !important;
  color: #f56c6c;
}
</style>
<style lang="scss" scoped>
.file-upload > input {
  display: none;
}
// .upload-input {
//   border: 1px solid #dcdfe6;
//   padding: 0 19px;
//   display: flex;
//   border-radius: 5px;
//   padding-right: 5px;
// }
.upload-display {
  flex-grow: 1;
}
</style>
